import {
  createRouter,
  createWebHistory
} from 'vue-router';

import { routes } from './routes';
import { nextTick } from 'vue';
import { isIOS } from '@repo/utils';
import { useWx } from '@repo/wx';

const router = createRouter({
  history: createWebHistory(),
  routes
});

const { wxConfig } = useWx();
router.afterEach(() => {
  if (!isIOS()) {
    nextTick(async () => {
      await wxConfig(location.href);
    });
  }
});

export { router };
